
      import API from "!../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../node_modules/style-loader/dist/runtime/singletonStyleDomAPI.js";
      
      import setAttributes from "!../node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../node_modules/style-loader/dist/runtime/insertStyleElement.js";
      
      import content, * as namedExport from "!!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/source-map-loader/dist/cjs.js!./chat-app.css";
      
      

var options = {};

;
options.setAttributes = setAttributes;
options.insert = function insertAtTop(element) {
            window.setTimeout(function() {
              var instance = document.querySelector('vivian-chat');
              instance.shadowRoot.appendChild(element);
            });
          };
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/source-map-loader/dist/cjs.js!./chat-app.css";
       export default content && content.locals ? content.locals : undefined;
